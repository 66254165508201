import '../../src/shared/styles/globals.css'
import 'react-phone-input-2/lib/material.css'
import React, { ReactNode } from 'react'
import Head from 'next/head'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import Router from 'next/router'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider, CssBaseline } from '@mui/material'
import createEmotionCache from '../../src/utils/createEmotionCache'
import lightTheme from '../../src/shared/styles/theme/lightTheme'
import { SWRConfigWrapper } from '../../src/components/SWRConfig'
import 'react-toastify/dist/ReactToastify.css'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { ToastContainer } from 'react-toastify'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { OnboardingPortalLayout } from '../components/Layouts/OnboardingPortalLayout'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { FirmSettingContextWrapperForEmployeePortal } from '../../src/components/contexts/FirmSettingContext/FirmSettingContext'

const RouteDependentOnboardingPortalAuthWrapper = dynamic(
    // @ts-ignore
    () =>
        import('../../src/components/AuthWrapper').then(
            (mod) => mod.RouteDependentOnboardingPortalAuthWrapper
        ),
    { ssr: false }
)

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_APP_ID!,
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        service: process.env.NEXT_PUBLIC_DD_SERVICE!,
        env: process.env.NEXT_PUBLIC_DD_ENV!,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'allow',
        trackUserInteractions: true,
        allowedTracingUrls: [
            RegExp(process.env.NEXT_PUBLIC_DD_ALLOWED_TRACING_ORIGINS!),
        ],
    })
    datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: ['error'],
        service: 'nextjs-worker-onboard-web-app',
    })
}

const clientSideEmotionCache = createEmotionCache()

const onRedirectCallback = (appState?: AppState | undefined) => {
    Router.replace(appState?.returnTo || '/')
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
    return (
        <Auth0Provider
            domain={process.env.NEXT_PUBLIC_DOMAIN ?? ''}
            clientId={process.env.NEXT_PUBLIC_ONBOARDING_PORTAL_CLIENT_ID ?? ''}
            audience={process.env.NEXT_PUBLIC_AUDIENCE}
            scope="read:users"
            redirectUri={
                typeof window !== 'undefined'
                    ? window.location.origin
                    : undefined
            }
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

const MyApp = (props: {
    Component: any
    emotionCache?: any
    pageProps: any
}) => {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps,
    } = props
    const getLayout = Component.getLayout || ((page: ReactNode) => page)
    const router = useRouter()

    return (
        <AuthProvider>
            <CacheProvider value={emotionCache}>
                <ThemeProvider theme={lightTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        {/* @ts-ignore */}
                        <RouteDependentOnboardingPortalAuthWrapper>
                            <FirmSettingContextWrapperForEmployeePortal>
                                <SWRConfigWrapper>
                                    <OnboardingPortalLayout>
                                        <Head>
                                            <title>Belfry</title>
                                            <meta
                                                name="description"
                                                content="Belfry"
                                            />
                                            <link
                                                rel="icon"
                                                href="/favicon.svg"
                                                type="image/svg+xml"
                                            />
                                            {/* <link rel="icon" href="/favicon.ico" sizes="any" /> */}

                                            <meta
                                                name="viewport"
                                                content="width=device-width, initial-scale=1"
                                            />
                                        </Head>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={true}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            theme="colored"
                                            pauseOnHover
                                        />

                                        {getLayout(
                                            <Component {...pageProps} />
                                        )}
                                    </OnboardingPortalLayout>
                                </SWRConfigWrapper>
                            </FirmSettingContextWrapperForEmployeePortal>
                        </RouteDependentOnboardingPortalAuthWrapper>
                    </LocalizationProvider>
                </ThemeProvider>
            </CacheProvider>
        </AuthProvider>
    )
}

export default MyApp
